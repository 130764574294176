var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},_vm._l((_vm.filtredOrders),function(order,index){return _c('div',{key:index,staticClass:"col s6 m4 l3 xl2 padding-small",on:{"click":function($event){return _vm.nextState(order)}}},[_c('div',{staticClass:"card bill-card",class:{
          'amber lighten-4':
            order.ready === 0 &&
            order.payed === 0 &&
            !order.die &&
            !order.alarm,
          'red lighten-4':
            order.ready === 0 &&
            order.payed === 1 &&
            !order.die &&
            !order.alarm,
          'teal lighten-4':
            order.ready === 1 &&
            order.payed === 1 &&
            !order.die,
          // 'purple lighten-4':
          //   (order.type === 'APP_OUT' || order.type === 'APP_IN') &&
          //   order.ready === 0 &&
          //   order.payed === 1 &&
          //   !order.die &&
          //   !order.alarm,
          // 'blue lighten-4':
          //   (order.type === 'APP_OUT' || order.type === 'APP_IN') &&
          //   order.ready === 1 &&
          //   order.payed === 1 &&
          //   !order.die,
          // 'amber lighten-1':
          //   order.type === 'DELIVERY' &&
          //   order.ready === 0 &&
          //   order.payed === 1 &&
          //   !order.die &&
          //   !order.alarm,
          // 'light-green lighten-3':
          //   order.type === 'DELIVERY' &&
          //   order.ready === 1 &&
          //   order.payed === 1 &&
          //   !order.die,
          'orange darken-4': order.alarm && !order.die && !order.ready,
          'grey darken-3': order.die
        }},[_c('div',{staticClass:"card-content ",class:{
            'white-text': order.alarm || order.die
          }},[_c('div',{staticClass:"card-title",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"inline",staticStyle:{"float":"left"}},[_vm._v(_vm._s(order.id))]),_c('div',{staticClass:"inline "},[_vm._v(_vm._s(order.type))]),_c('div',{staticClass:"inline",staticStyle:{"float":"right"}},[_c('OrderTime',{attrs:{"time":order,"corner":_vm.corner}})],1)]),(order.positions)?_c('div',{staticStyle:{"height":"240px","overflow-y":"auto"}},_vm._l((order.positions),function(pos,index){return _c('div',{key:index},[_c('p',[_c('span',[_vm._v(_vm._s(pos.count || 1)+" x ")]),_c('span',[_vm._v(_vm._s(pos.name)),_c('sup',[_vm._v("("+_vm._s(pos.station)+")")])])]),_vm._l((pos.mods),function(mod,index){return _c('p',{key:index},[_c('small',{staticClass:"helper-text ",staticStyle:{"margin-left":"10%"}},[_vm._v(" -"+_vm._s(mod.name))])])})],2)}),0):_vm._e(),_c('div',{staticClass:"footer-copyright"},[_c('div',{},[_vm._v(" "+_vm._s(order.text)+" "),(order.pin)?_c('a',{staticClass:"grey-text text-lighten-4 right",attrs:{"href":"#!"}},[_vm._v("PIN: "+_vm._s(order.pin))]):_vm._e()])])])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }