import { render, staticRenderFns } from "./Corners.vue?vue&type=template&id=11aacff1&scoped=true&"
import script from "./Corners.vue?vue&type=script&lang=js&"
export * from "./Corners.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11aacff1",
  null
  
)

export default component.exports