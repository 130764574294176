import { render, staticRenderFns } from "./positions.vue?vue&type=template&id=6d3f55a8&scoped=true&"
import script from "./positions.vue?vue&type=script&lang=js&"
export * from "./positions.vue?vue&type=script&lang=js&"
import style0 from "./positions.vue?vue&type=style&index=0&id=6d3f55a8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d3f55a8",
  null
  
)

export default component.exports