var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},_vm._l((_vm.filtreditems),function(item,index){return _c('div',{key:index,staticClass:"col s6 m4 l3 xl2 padding-small"},[_c('div',{staticClass:"card bill-card amber lighten-1",class:{
          'amber lighten-5':
            (item.type === 'IN' || item.type === 'OUT') &&
            item.ready === 0 &&
            item.payed === 0 &&
            !item.die &&
            !item.alarm,
          'red lighten-5':
            (item.type === 'IN' || item.type === 'OUT') &&
            item.ready === 0 &&
            item.payed === 1 &&
            !item.die &&
            !item.alarm,
          'teal lighten-4':
            (item.type === 'IN' || item.type === 'OUT') &&
            item.ready === 1 &&
            item.payed === 1 &&
            !item.die,
          'purple lighten-4':
            item.type === 'APP' &&
            item.ready === 0 &&
            item.payed === 1 &&
            !item.die &&
            !item.alarm,
          'blue lighten-4':
            item.type === 'APP' &&
            item.ready === 1 &&
            item.payed === 1 &&
            !item.die,
          'amber lighten-1':
            item.type === 'DELIVERY' &&
            item.ready === 0 &&
            item.payed === 1 &&
            !item.die &&
            !item.alarm,
          'light-green lighten-3':
            item.type === 'DELIVERY' &&
            item.ready === 1 &&
            item.payed === 1 &&
            !item.die,
          'orange darken-4': item.alarm && !item.die && !item.ready,
          'grey darken-3': item.die
        }},[_c('div',{staticClass:"card-content ",class:{
            'white-text': item.alarm || item.die
          }},[_c('div',{staticClass:"card-title",staticStyle:{"text-align":"center","overflow":"hidden","height":"1.8em","line-height":"0.9"}},[_c('div',{staticClass:"inline"},[_vm._v(_vm._s(item.name))])]),(item.lot)?_c('div',{staticStyle:{"height":"150px","overflow-y":"auto"}},_vm._l((item.lot),function(pos,posIndex){return _c('div',{key:posIndex},[_c('p',{class:{
                'blue-text': pos.ready && !pos.die,
                'red-text': !pos.ready && !pos.die,
                'black-text': pos.die,
                'linethrough grey-text': pos.ready && pos.use >= pos.count

              }},[_c('span',[_vm._v("[Партия "+_vm._s(pos.count)+" шт]: ")]),_c('span',[_c('b',[_vm._v("Ост "+_vm._s(pos.count - pos.use)+" шт.")])]),_c('span',[_c('b',[_vm._v(_vm._s(pos.die?'(Списать!)':''))])]),_c('span',[_c('b',[_vm._v(_vm._s(!pos.ready?'(Готовим!)':''))])])])])}),0):_vm._e(),(_vm.inProgress(item))?_c('button',{staticClass:"btn waves-effect waves-light auth-submit ",attrs:{"type":"submit"},on:{"click":function($event){return _vm.setReady(item)}}},[_vm._v(" Готово "),_c('i',{staticClass:"material-icons right"},[_vm._v("star")])]):_vm._e(),_c('br'),_c('br'),(_vm.anyDie(item))?_c('button',{staticClass:"btn waves-effect waves-light auth-submit",attrs:{"type":"submit"},on:{"click":function($event){return _vm.setDie(item)}}},[_vm._v(" Списать "),_c('i',{staticClass:"material-icons right"},[_vm._v("delete")])]):_vm._e(),_c('div',{staticClass:"footer-copyright"},[_c('div',{},[_vm._v(" "+_vm._s(item.text)+" "),(item.pin)?_c('a',{staticClass:"grey-text text-lighten-4 right",attrs:{"href":"#!"}},[_vm._v("PIN: "+_vm._s(item.pin))]):_vm._e()])])])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }